export function useTabFilters() {
  const currentTabValue = useState('currentTabValue', () => null)
  const articles = ref(null)
  const storyblokApi = useStoryblokApi()
  const route = useRoute()
  const router = useRouter()
  const isLoading = ref(true)
  const startsWith = route.fullPath === '/reviews' ? 'reviews' : 'blog'
  const setCurrentTabValue = (value: string) => {
    currentTabValue.value = value
    router.push({
      query: {
        ...route.query,
        tag: value,
      },
    })
  }
  async function getSitemapUrls() {
    const links = await storyblokApi.getAll('cdn/links', {
      version: 'published',
    })
    return links.map(p => asSitemapUrl({
      loc: p.slug,
    }))
  }
  async function getPostsCategories() {
    const response = await storyblokApi.get('cdn/datasource_entries/')
    return response.data.datasource_entries
  }
  async function getArticles() {
    isLoading.value = true
    const { data } = await storyblokApi.get('cdn/stories', {
      version: useRoute().query._storyblok ? 'draft' : 'published',
      starts_with: startsWith,
      sort_by: 'content.created_at:desc',
      is_startpage: false,
      filter_query: {
        tag: {
          in: currentTabValue.value,
        },
      },
    })
    articles.value = data.stories
    isLoading.value = false
  }
  watch(currentTabValue, () => {
    getArticles()
  })
  return {
    currentTabValue,
    setCurrentTabValue,
    getArticles,
    getSitemapUrls,
    getPostsCategories,
    articles,
    isLoading,
  }
}
